import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import { User } from '../../../typings/backend-types';
import { useUserTeam } from '../../../hooks/useUserTeam';
import { formatName } from '../../../utilities/formatName';
import { BasicSelectInput } from '../../Forms/FormFields/BasicSelectInput';
import { StudentsWithoutAppointmentTile } from './StudentsWithoutAppointmentTile';

export const TeamStudentsWithoutAppointmentTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.studentsWithoutAppointment',
  });

  const team = useUserTeam();
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    team[0]?.id,
  );

  useEffect(() => {
    if (team.length > 0) {
      setSelectedUserId(team[0].id);
    }
  }, [team]);

  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForInstructor(selectedUserId, {
      withoutAppointment: true,
      includeBalance: false,
      archived: false,
    });

  return (
    <StudentsWithoutAppointmentTile
      fetchUrl={fetchUrl}
      showInstructorColumn={false}
      title={t('teamStudentsTableHeader')}
    >
      <BasicSelectInput<User>
        small
        hideNoneSelect={team.length > 0}
        className="mr-2"
        options={team}
        bindLabel={formatName}
        bindValue={(user) => user.id}
        value={selectedUserId}
        onChange={(e) => setSelectedUserId(e.target.value)}
      />
    </StudentsWithoutAppointmentTile>
  );
};
