import { FunctionComponent } from 'react';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { UserType, useAppContext } from '../../context/appContext';
import { InfoBlob } from '../TimeTracking/InfoBlob';
import { getColor, getTextColor } from '../../utilities/textColorByNumber';
import { formatCurrency } from '../../utilities/formatCurrency';
import { SectionHeading } from '../Typography/SectionHeading';
import { StudentLessonList } from '../Students/StudentLessonList';
import { Panel } from '../Common/Panel';
import { StudentPaymentInitiationForm } from './StudentPaymentInitiationForm';

export const StudentDashboard: FunctionComponent = () => {
  const { user } = useAppContext(UserType.STUDENT);
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  return (
    <>
      <Panel className="mb-5">
        <div className="flex justify-between items-center flex-wrap-reverse gap-y-5">
          <InfoBlob
            title={t('yourBalance')}
            icon={BanknotesIcon}
            color={getColor(user.balance)}
          >
            <span className={getTextColor(user.balance)}>
              {formatCurrency(user.balance)}
            </span>
          </InfoBlob>
          <div>
            <StudentPaymentInitiationForm />
          </div>
        </div>
      </Panel>
      <Panel>
        <SectionHeading>{t('lessonList')}</SectionHeading>
        <StudentLessonList />
      </Panel>
    </>
  );
};
