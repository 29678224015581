import { FunctionComponent } from 'react';
import { useAppContext } from '../../context/appContext';
import { HttpEndpoints } from '../../data/httpEndpoints';

import useContract, { RestrictedFeatures } from '../../hooks/useContract';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { DashboardComponent } from '../../typings/backend-types';
import { StudentTaskTile } from './StudentTaskTile';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';

export const MyStudentsTaskTile: FunctionComponent = () => {
  const { userId } = useAppContext();

  const { isFeatureEnabled } = useContract();
  const canSeeOwnStudents = useUserPermission(
    Permission.OWN_STUDENTS_INFO,
    Scope.READ,
  );

  if (!canSeeOwnStudents) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.OWN_STUDENT_TASKS}
      />
    );
  }

  return (
    <StudentTaskTile
      incompleteProgressCardsFetchUrl={() =>
        isFeatureEnabled(RestrictedFeatures.SYLLABI) &&
        HttpEndpoints.ProgressCardEndpoints.getIncompleteCards(userId)
      }
      fetchUrl={() =>
        HttpEndpoints.StudentEndpoints.getStudentsForInstructor(userId, {
          archived: false,
        })
      }
      includeInstructorColumn={false}
    />
  );
};
