import dayjs, { Dayjs } from 'dayjs';
import sum from 'lodash/sum';
import { Duration } from 'dayjs/plugin/duration';
import { blue } from 'easycolors/build/tailwind';
import { tailwind } from 'easycolors';
import { getLicenseCategoryColor } from '../../utilities/colorForLicenseCategory';
import {
  Color,
  CreateTimesheetDto,
  UpdateTimesheetDto,
  WorkEvent,
} from '@tr-types/backend-types';
import authenticatedPost from 'data/authenticatedPost';
import { HttpEndpoints } from 'data/httpEndpoints';

export function getLicenseCategoryColorHex(lc: string): string {
  const color = getLicenseCategoryColor(lc);
  if (color === Color.Blue) return blue[400];
  return tailwind[color][500];
}

export const NO_BILLING_TYPE_TAG = 'no-tag';

export function getBillingTypeTagColor(billingTypeTagId: string): string {
  return tailwind[getBillingTypeTagTailwindColor(billingTypeTagId)][500];
}
export function getBillingTypeTagTailwindColor(
  billingTypeTagId: string,
): Color {
  if (billingTypeTagId === NO_BILLING_TYPE_TAG) return Color.Gray;

  const keys = [
    Color.Purple,
    Color.Yellow,
    Color.Blue,
    Color.Orange,
    Color.Green,
  ];
  const index = (billingTypeTagId.codePointAt(0) || 0) % keys.length;
  return keys[index];
}

export function durationToReadable(durationHours: number): string {
  const duration = dayjs.duration(durationHours, 'h');
  return `${Math.floor(duration.asHours())}h ${duration.minutes()}m`;
}

export function durationTotalsFromCategories(
  eventsGroupedByCategories: Record<string, WorkEvent[]>,
): Duration[] {
  const millisecondsPerMinute = 60000;

  return Object.values(eventsGroupedByCategories).map((events) =>
    dayjs.duration(
      sum(
        events.map(
          (e) =>
            dayjs(e.end_time).diff(e.start_time) +
            (e.billingType?.shadow_time ?? 0) * millisecondsPerMinute,
        ),
      ),
    ),
  );
}

export function createOrUpdateTimesheet(
  timesheetId: string | undefined,
  userId: string,
  body: CreateTimesheetDto | UpdateTimesheetDto,
): Promise<Response> {
  if (timesheetId) {
    return authenticatedPost(
      HttpEndpoints.TimesheetEndpoints.patchTimesheet(timesheetId, userId),
      body,
      'PATCH',
    );
  }
  return authenticatedPost(
    HttpEndpoints.TimesheetEndpoints.postTimesheet(userId),
    body,
  );
}

export function getFirstDayOfMonth(month: number, year: number): Dayjs {
  return dayjs().year(year).month(month).startOf('month');
}
