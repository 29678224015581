import { FunctionComponent } from 'react';
import { classNames } from '../../utilities/classNames';

interface Props {
  className?: string;
}

export const SectionHeading: FunctionComponent<Props> = ({
  className,
  children,
}) => {
  return (
    <h2 className={classNames('mb-3 text-gray-800 font-semibold', className)}>
      {children}
    </h2>
  );
};
