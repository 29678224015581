import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import { User } from '../../../typings/backend-types';
import { useUserTeam } from '../../../hooks/useUserTeam';
import { formatName } from '../../../utilities/formatName';
import { BasicSelectInput } from '../../Forms/FormFields/BasicSelectInput';
import { StudentsTile } from './StudentsTile';

export const TeamStudentsTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.drivingStudents',
  });
  const team = useUserTeam();
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    team[0]?.id,
  );

  useEffect(() => {
    if (team.length > 0) {
      setSelectedUserId(team[0].id);
    }
  }, [team]);

  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForInstructor(selectedUserId, {
      archived: false,
    });

  return (
    <StudentsTile fetchUrl={fetchUrl} title={t('teamStudents')}>
      <BasicSelectInput<User>
        small
        hideNoneSelect={team.length > 0}
        className="mr-2"
        options={team}
        bindLabel={formatName}
        bindValue={(user) => user.id}
        value={selectedUserId}
        onChange={(e) => setSelectedUserId(e.target.value)}
      />
    </StudentsTile>
  );
};
