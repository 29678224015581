import { FunctionComponent } from 'react';
import Link from 'next/link';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { Panel } from '../Common/Panel';
import { Routes } from '../../utilities/routes';
import { classNames } from '../../utilities/classNames';
import { HttpEndpoints } from '../../data/httpEndpoints';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { UserType, useAppContext } from '../../context/appContext';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { DashboardComponent } from '../../typings/backend-types';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';

export const DashboardTodoMention: FunctionComponent = () => {
  const { user } = useAppContext(UserType.EMPLOYEE);

  const { data: todoCount } = useSWR<number>(
    () => HttpEndpoints.TodoEndpoints.countForAssignedUser(user.id, false),
    authenticatedFetcher,
  );

  const { t } = useTranslation('translation', { keyPrefix: 'todos' });

  const canSeeOwnTodos = useUserPermission(Permission.OWN_TODOS, Scope.READ);

  if (!canSeeOwnTodos) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.TODO_COUNT}
      />
    );
  }

  return (
    !!todoCount && (
      <Panel className="lg:col-span-2 text-base break-inside-avoid">
        <span
          className={classNames(
            'bg-magenta-600 rounded-full text-white py-px',
            todoCount > 1 ? 'px-2' : 'px-[10px]',
          )}
        >
          {todoCount}
        </span>{' '}
        <Link href={Routes.Todos.Overview} passHref>
          <a className="text-accent-600 hover:text-accent-800 inline-flex items-center">
            {t('incompleteTodos', { count: todoCount })}
            <ChevronRightIcon className="w-5 h-5 inline-block m-1" />
          </a>
        </Link>
      </Panel>
    )
  );
};
