import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { InfoText } from '../Common/InfoText';
import { DashboardComponent } from '../../typings/backend-types';

export const DashboardPermissionMissingHint: FunctionComponent<{
  dashboardComponent: DashboardComponent;
}> = ({ dashboardComponent }) => {
  const { t } = useTranslation();

  return (
    <InfoText>
      {t('dashboard.permissionMissing', {
        title: t(
          'organization.roles.dashboardComponent.' +
            camelCase(dashboardComponent),
        ),
      })}
    </InfoText>
  );
};
