import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useErrorPopupContext } from '../../../context/errorPopupContext';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { Student } from '../../../typings/backend-types';
import { Routes } from '../../../utilities/routes';
import { Panel } from '../../Common/Panel';
import { PagedTable } from '../../Table/PagedTable';
import { Column } from '../../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../../Typography/SectionHeading';
import { DynamicExportButton } from '../../Common/ExportImport/DynamicExportButton';
import useUserPermission from '../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../typings/roleConfig';
import { formatNameWithLabel } from '../../../utilities/students/formatNameWithLabel';
import { formatName } from '../../../utilities/formatName';

interface Props {
  fetchUrl: () => string | undefined;
  showInstructorColumn: boolean;
  title: string;
}

export const StudentsWithoutAppointmentTile: FunctionComponent<Props> = ({
  fetchUrl,
  showInstructorColumn,
  title,
  children,
}) => {
  const { setErrorMessage } = useErrorPopupContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.studentsWithoutAppointment',
  });

  const canExportStudents = useUserPermission(
    Permission.STUDENT_EXPORT,
    Scope.READ,
  );

  const { data: studentsWithoutAppointment } = useSWR<Student[]>(
    fetchUrl,
    authenticatedFetcher,
    { fallbackData: [] },
  );

  const columns: Column<Student>[] = [
    {
      header: t('studentNameColumn'),
      prop: (student: Student) => formatNameWithLabel(student),
    },
  ];

  if (showInstructorColumn) {
    columns.push({
      header: t('instructorColumn'),
      prop: (student: Student) => formatName(student.instructor),
    });
  }

  return (
    <Panel className="break-inside-avoid">
      <div className="flex items-center">
        <SectionHeading className="flex-grow mb-0 mr-3">{title}</SectionHeading>
        {children}
        {canExportStudents && (
          <DynamicExportButton
            small
            data={studentsWithoutAppointment.map((student) => ({
              [t('studentNameColumn')]: formatName(student),
              [t('instructorColumn')]: formatName(student.instructor),
            }))}
            exportFileName={title}
          />
        )}
      </div>

      <PagedTable
        fetchUrl={fetchUrl}
        columns={columns}
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        limit={5}
        syncPageWithQueryParamKey="studentsWithoutAppointmentPage"
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={({ id }) => Routes.Students.DetailView(id)}
      />
    </Panel>
  );
};
