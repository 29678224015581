import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../context/appContext';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import { DashboardComponent } from '../../../typings/backend-types';
import useUserPermission from '../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../typings/roleConfig';
import { DashboardPermissionMissingHint } from '../PermissionMissingHint';
import { StudentsWithoutAppointmentTile } from './StudentsWithoutAppointmentTile';

export const MyStudentsWithoutAppointmentTile: FunctionComponent = () => {
  const { user } = useAppContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.studentsWithoutAppointment',
  });

  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForInstructor(user.id, {
      withoutAppointment: true,
      includeBalance: false,
      archived: false,
    });

  const canSeeOwnStudents = useUserPermission(
    Permission.OWN_STUDENTS_INFO,
    Scope.READ,
  );

  if (!canSeeOwnStudents) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={
          DashboardComponent.OWN_STUDENTS_INFO_WITHOUT_APPOINTMENT
        }
      />
    );
  }

  return (
    <StudentsWithoutAppointmentTile
      title={t('myStudentsTableHeader')}
      showInstructorColumn={false}
      fetchUrl={fetchUrl}
    />
  );
};
