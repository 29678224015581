import { CheckCircleIcon, BuildingOfficeIcon } from '@heroicons/react/24/solid';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { AuthorizedPageWrapper } from '../../components/PageContentWrapper/AuthorizedPageWrapper';

import { StudentDashboard } from '../../components/Dashboard/StudentDashboard';
import { EmployeeDashboard } from '../../components/Dashboard/EmployeeDashboard';
import { UserType, useAppContext } from 'context/appContext';

export default function Dashboard(): JSX.Element {
  const { user: auth0User } = useAuth0();
  const { user, userType } = useAppContext(UserType.BOTH);
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  function getHighestUserRole(): string {
    if (userType === UserType.STUDENT) return t('roles.student');
    return user.role?.title ?? '';
  }

  const header = (
    <div className="flex-1 min-w-0">
      {/* Profile */}
      <div className="flex items-center">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          className="hidden h-16 w-16 rounded-full sm:block"
          src={auth0User.picture}
          alt=""
        />
        <div>
          <div className="flex items-center">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              className="h-16 w-16 rounded-full sm:hidden"
              src={auth0User.picture}
              alt=""
            />
            <h1 className="ml-3 text-2xl font-semibold leading-7 text-gray-800 sm:leading-9 sm:truncate">
              {t('greeting', { name: user?.firstName })}
            </h1>
          </div>

          <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
            <dt className="sr-only">{t('organization')}</dt>
            <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
              <BuildingOfficeIcon
                className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-500"
                aria-hidden="true"
              />
              {[
                user?.organization?.name,
                user?.organization?.contact_info?.address?.city,
                user?.organization?.contact_info?.address?.country,
              ]
                .filter(Boolean)
                .join(', ')}
            </dd>
            <dt className="sr-only">{t('accountStatus')}</dt>
            <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0">
              <CheckCircleIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                aria-hidden="true"
              />
              {getHighestUserRole()}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );

  return (
    <AuthorizedPageWrapper
      pageTitle={t('pageTitle')}
      authorizationRequirements={null}
      headerComponent={header}
    >
      {userType === UserType.EMPLOYEE && <EmployeeDashboard />}
      {userType === UserType.STUDENT && <StudentDashboard />}
    </AuthorizedPageWrapper>
  );
}
