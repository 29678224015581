import React, { useEffect, useState } from 'react';
import useSWR, { KeyedMutator } from 'swr';
import { useRouter } from 'next/router';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
  Column,
  ExportOptions,
  SortedFilteredTable,
  SortingOptions,
} from './SortedFilteredTable/SortedFilteredTable';
import { getFallbackLimit } from './SSPagedTable/SSPagedTable';
import authenticatedFetcher from 'data/authenticatedFetcher';

export type FetchUrl = (
  query?: string,
  sortOptions?: SortingOptions,
) => string | undefined | false;

export type AdditionalButton =
  | JSX.Element
  | ((query: string, sort: SortingOptions) => JSX.Element);

interface Props<T> {
  fetchUrl: FetchUrl;
  columns: Column<T>[];
  altText?: string | null;
  onRowClick?: (row: T) => void;
  rowLink?: (row: T) => string;
  searchPlaceholder?: string;
  showSearch?: boolean;
  limit?: number;
  onError?: (e: unknown) => void;
  filter?: (row?: T) => boolean;
  exportOptions?: ExportOptions<T>;
  toolbar?: (
    rows: T[],
    isLoading: boolean,
    mutate: KeyedMutator<T[] | null>,
  ) => JSX.Element;
  additionalButtons?: AdditionalButton | AdditionalButton[];
  onRowsChange?: (rows: T[]) => void;
  onIsValidatingChange?: (value: boolean) => void;
  dedupingInterval?: number;
  revalidateOnFocus?: boolean;
  syncPageWithQueryParamKey?: string;
  wrapToolbar?: boolean;
}

/**
 * A wrapper for the tailwind simple table https://tailwindui.com/components/application-ui/lists/tables#component-3de290cc969415f170748791a9d263a6
 * Contains a footer for navigation if the parameters from, to and count and/or onPrev and onNext are provided
 * for an example, see StudentsOverview
 */
export function PagedTable<T extends { id?: string }>({
  fetchUrl,
  filter,
  columns,
  altText,
  onRowClick,
  rowLink,
  onError,
  limit: limitProp,
  searchPlaceholder,
  showSearch,
  exportOptions,
  additionalButtons,
  toolbar,
  onRowsChange,
  onIsValidatingChange,
  dedupingInterval,
  syncPageWithQueryParamKey,
  wrapToolbar,
  revalidateOnFocus,
}: Props<T>): JSX.Element {
  const { width } = useWindowDimensions();
  const limit = limitProp ?? getFallbackLimit(width);

  const [filterText, setFilterText] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(0);
  const [count, setCount] = useState(0);

  const router = useRouter();
  const routerQuery = router.query;

  const [sortOptions, setSortOptions] = useState<SortingOptions>({
    sortBy: undefined,
    direction: undefined,
  });

  const {
    data: fetchedData,
    isValidating,
    mutate,
  } = useSWR<T[] | null>(
    () => fetchUrl(filterText, sortOptions),
    authenticatedFetcher,
    {
      onError: (err) => onError?.(err),
      revalidateOnFocus: revalidateOnFocus ?? true,
      dedupingInterval: dedupingInterval,
    },
  );

  const [rows, setRows] = useState<T[]>([]);

  useEffect(() => {
    if (!fetchedData) return;
    const filteredData = fetchedData.filter(filter ?? (() => true));
    setRows(filteredData ?? []);
    setCount(filteredData.length);

    if (
      syncPageWithQueryParamKey &&
      routerQuery[syncPageWithQueryParamKey] != null
    ) {
      const newFrom = Number(routerQuery[syncPageWithQueryParamKey]);
      if (!isNaN(newFrom)) updatePosition(newFrom, false);
    } else {
      updatePosition(1, false, filteredData.length);
    }

    // this useEffect shouldn't be called on changes to updatePosition
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    routerQuery,
    limit,
    count,
    syncPageWithQueryParamKey,
    fetchedData,
    filter,
  ]);

  useEffect(() => {
    onIsValidatingChange?.(isValidating);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidating]);

  useEffect(() => {
    onRowsChange?.(rows);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  function onPrev() {
    if (from <= 1) return;
    updatePosition(from - limit);
  }

  function onNext() {
    if (to >= count) return;
    updatePosition(from + limit);
  }

  function updatePosition(
    newFrom: number,
    updateRouterQuery = true,
    c = count,
  ) {
    newFrom = Math.max(newFrom, 1);
    newFrom = Math.min(newFrom, c);

    if (syncPageWithQueryParamKey && updateRouterQuery) {
      void router.replace(
        { query: { ...router.query, [syncPageWithQueryParamKey]: newFrom } },
        undefined,
        {
          shallow: true,
        },
      );
    }
    setFrom(newFrom);
    setTo(Math.min(newFrom + limit - 1, c));
  }

  return (
    <SortedFilteredTable
      columns={columns}
      altText={altText}
      wrapToolbar={wrapToolbar}
      onRowClick={onRowClick}
      rowLink={rowLink}
      searchPlaceholder={searchPlaceholder}
      showSearch={showSearch}
      exportOptions={{ ...exportOptions, exportData: rows }}
      toolbar={!!rows && toolbar?.(rows, isValidating, mutate)}
      additionalButtons={additionalButtons}
      rows={rows.slice(from - 1, to)}
      from={from}
      totalCount={count}
      isValidating={isValidating}
      onNext={onNext}
      onPrev={onPrev}
      onSortOptionsChange={setSortOptions}
      onFilterTextChange={setFilterText}
    />
  );
}

PagedTable.defaultProps = {
  showSearch: true,
  exportOptions: {
    exportTransform: (i: unknown) => i,
    exportFileName: 'ExportData',
  },
  filter: () => true,
};
