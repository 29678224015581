import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../context/appContext';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { HttpEndpoints } from '../../data/httpEndpoints';
import {
  DashboardComponent,
  InvoiceSort,
  InvoiceStatusFilter,
  InvoiceSummary,
} from '../../typings/backend-types';
import { displayDateFormat } from '../../utilities/dateFormat';
import { Routes } from '../../utilities/routes';
import { Panel } from '../Common/Panel';
import { PagedTable } from '../Table/PagedTable';
import { Column } from '../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../Typography/SectionHeading';
import useContract, { RestrictedFeatures } from '../../hooks/useContract';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { formatNameWithLabel } from '../../utilities/students/formatNameWithLabel';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';

export const OverdueInvoicesTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.overdueInvoices',
  });
  const { organizationId } = useAppContext();
  const { isFeatureEnabled } = useContract();
  const { setErrorMessage } = useErrorPopupContext();

  const columns: Column<InvoiceSummary>[] = [
    {
      header: '#',
      prop: (i) => i.invoice_number,
    },
    {
      header: t('columns.studentName'),
      prop: (i) => formatNameWithLabel(i.student),
    },
    {
      header: t('columns.dueDate'),
      prop: (i) => dayjs(i.payment_due_date).format(displayDateFormat),
    },
  ];

  const canSeeInvoices = useUserPermission(Permission.ALL_INVOICES, Scope.READ);

  if (!canSeeInvoices) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.OVERDUE_INVOICES}
      />
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <SectionHeading>{t('tableHeader')}</SectionHeading>
      <PagedTable
        fetchUrl={() =>
          HttpEndpoints.InvoiceEndpoints.getInvoicesForOrganization(
            organizationId,
            {
              status: InvoiceStatusFilter.OVERDUE,
              sort: { sortBy: InvoiceSort.DUE_DATE },
            },
          )
        }
        columns={columns}
        limit={5}
        syncPageWithQueryParamKey="overdueInvoicesPage"
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={(invoice) => {
          if (!isFeatureEnabled(RestrictedFeatures.FINANCE_OVERVIEW)) {
            return Routes.Students.Invoices(invoice.student.id).DetailView(
              invoice.id,
            );
          }
          return Routes.Invoices.DetailView(invoice.id);
        }}
      />
    </Panel>
  );
};
