import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import { useErrorPopupContext } from '../../../context/errorPopupContext';
import authenticatedFetcher from '../../../data/authenticatedFetcher';
import { Student } from '../../../typings/backend-types';
import { displayDateFormat } from '../../../utilities/dateFormat';
import { formatCurrency } from '../../../utilities/formatCurrency';
import { formatName } from '../../../utilities/formatName';
import { Routes } from '../../../utilities/routes';
import { formatNameWithLabel } from '../../../utilities/students/formatNameWithLabel';
import { DynamicExportButton } from '../../Common/ExportImport/DynamicExportButton';
import { Panel } from '../../Common/Panel';
import { PagedTable } from '../../Table/PagedTable';
import { Column } from '../../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../../Typography/SectionHeading';

interface Props {
  fetchUrl: () => string | undefined;
  title: string;
}

export const StudentsTile: FunctionComponent<Props> = ({
  fetchUrl,
  title,
  children,
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.drivingStudents',
  });
  const { setErrorMessage } = useErrorPopupContext();

  const { data: students } = useSWR<Student[]>(fetchUrl, authenticatedFetcher, {
    fallbackData: [],
  });

  const studentsColumn: Column<Student>[] = [
    {
      header: t('studentNameColumn'),
      prop: formatNameWithLabel,
    },
    {
      header: t('dateOfBirthColumn'),
      prop: (student: Student) =>
        student.dateOfBirth &&
        dayjs(student.dateOfBirth).format(displayDateFormat),
    },
    {
      header: t('balanceColumn'),
      prop: (student: Student) => formatCurrency(student.balance),
    },
  ];

  return (
    <Panel className="break-inside-avoid">
      <div className="flex overflow-visible">
        <SectionHeading className="flex-grow">{title}</SectionHeading>
        {children}
        <DynamicExportButton
          small
          data={students.map((s) => ({
            [t('studentNameColumn')]: formatName(s),
            [t('dateOfBirthColumn')]:
              s.dateOfBirth && dayjs(s.dateOfBirth).format(displayDateFormat),
            [t('balanceColumn')]: formatCurrency(s.balance),
          }))}
          exportFileName={title}
        />
      </div>
      <PagedTable
        fetchUrl={fetchUrl}
        columns={studentsColumn}
        limit={5}
        syncPageWithQueryParamKey="myStudentsPage"
        onError={() => setErrorMessage(t('couldNotFetchMessage'))}
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={({ id }) => Routes.Students.DetailView(id)}
      />
    </Panel>
  );
};
