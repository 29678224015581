import { FunctionComponent } from 'react';
import { useAppContext } from '../../context/appContext';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { DashboardComponent, StudentSort } from '../../typings/backend-types';
import useContract, { RestrictedFeatures } from '../../hooks/useContract';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { StudentTaskTile } from './StudentTaskTile';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';

export const AllStudentsTaskTile: FunctionComponent = () => {
  const { user, organizationId } = useAppContext();

  const { isFeatureEnabled } = useContract();
  const canSeeAllStudents = useUserPermission(
    Permission.ALL_STUDENTS_INFO,
    Scope.READ,
  );

  if (!canSeeAllStudents) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.ALL_STUDENT_TASKS}
      />
    );
  }

  return (
    <StudentTaskTile
      incompleteProgressCardsFetchUrl={() =>
        isFeatureEnabled(RestrictedFeatures.SYLLABI) &&
        HttpEndpoints.ProgressCardEndpoints.getIncompleteCards(
          undefined,
          organizationId,
        )
      }
      fetchUrl={() =>
        HttpEndpoints.StudentEndpoints.getStudentsForOrganization(
          user.organization.id,
          { sortBy: StudentSort.INSTRUCTOR, archived: false },
        )
      }
      includeInstructorColumn
    />
  );
};
