import { Color } from '../typings/backend-types';

export function getLicenseCategoryColor(lc: string): Color {
  if (lc.startsWith('A')) return Color.Red;
  if (lc.startsWith('B')) return Color.Purple;
  if (lc.startsWith('C')) return Color.Yellow;
  if (lc.startsWith('D')) return Color.Blue;
  if (lc.startsWith('F')) return Color.Orange;
  return Color.Gray;
}
