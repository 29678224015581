import dayjs from 'dayjs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../context/appContext';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { displayDateFormat } from '../../utilities/dateFormat';
import { CalendarWithPopups } from '../Calendar/CalendarWithPopups';
import { Panel } from '../Common/Panel';
import { SectionHeading } from '../Typography/SectionHeading';
import useUserPermission from '../../hooks/useUserPermission';
import { Permission, Scope } from '../../typings/roleConfig';
import { DashboardComponent } from '../../typings/backend-types';
import { DashboardPermissionMissingHint } from './PermissionMissingHint';

export const CalendarTile: FunctionComponent = () => {
  const { userId } = useAppContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.calendar',
  });

  const canSeeOwnCalendar = useUserPermission(
    Permission.OWN_CALENDAR,
    Scope.READ,
  );

  if (!canSeeOwnCalendar) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.MY_DAY}
      />
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <SectionHeading>
        {t('myDay')}{' '}
        <span className="text-gray-400 font-normal text-base">
          ({dayjs().format(displayDateFormat)})
        </span>
      </SectionHeading>
      <CalendarWithPopups
        workEventsFetchUrl={(from, to) => () => {
          const timespan = from?.isValid?.() &&
            to?.isValid?.() && {
              timespanBegin: from.toISOString(),
              timespanEnd: to.toISOString(),
            };
          return HttpEndpoints.WorkEventEndpoints.getWorkEventsForUser(
            userId,
            timespan,
          );
        }}
        vacationEventsFetchUrl={(from, to) => () => {
          const timespan = from?.isValid?.() &&
            to?.isValid?.() && {
              timespanBegin: from.toISOString(),
              timespanEnd: to.toISOString(),
            };
          return HttpEndpoints.VacationEventEndpoints.getVacationEventsForUser(
            userId,
            timespan,
          );
        }}
        calendarOptions={{
          initialView: 'timeGridDay',
          headerToolbar: false,
          dayHeaders: false,
          height: 370,
        }}
        swipeable={false}
      />
    </Panel>
  );
};
