import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../context/appContext';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import useUserPermission from '../../../hooks/useUserPermission';
import { DashboardComponent } from '../../../typings/backend-types';
import { Permission, Scope } from '../../../typings/roleConfig';
import { DashboardPermissionMissingHint } from '../PermissionMissingHint';
import { StudentsWithoutAppointmentTile } from './StudentsWithoutAppointmentTile';

export const AllStudentsWithoutAppointmentTile: FunctionComponent = () => {
  const { organizationId } = useAppContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.studentsWithoutAppointment',
  });

  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForOrganization(organizationId, {
      withoutAppointment: true,
      includeBalance: false,
      archived: false,
    });

  const canSeeAllStudents = useUserPermission(
    Permission.ALL_STUDENTS_INFO,
    Scope.READ,
  );

  if (!canSeeAllStudents) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.ALL_STUDENTS_WITHOUT_APPOINTMENT}
      />
    );
  }

  return (
    <StudentsWithoutAppointmentTile
      fetchUrl={fetchUrl}
      showInstructorColumn
      title={t('allStudentsTableHeader')}
    />
  );
};
