import { useEffect } from 'react';
import { useNotificationContext } from '../../context/notificationContext';
import { useRouter } from 'next/router';
import {
  ExclamationCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

enum DatatransPaymentStatus {
  SUCCESS = 'success',
  ERROR = 'error',
  CANCELLED = 'cancelled',
}

export function useDatatransPaymentFeedback() {
  const { setNotification } = useNotificationContext();
  const router = useRouter();
  const { payment_status }: { payment_status?: DatatransPaymentStatus } =
    router.query;

  const { t } = useTranslation('translation', {
    keyPrefix: 'datatransPaymentFeedback',
  });

  useEffect(() => {
    switch (payment_status) {
      case DatatransPaymentStatus.SUCCESS:
        setNotification({
          title: t('paymentSucceeded.title'),
          message: t('paymentSucceeded.message'),
          maxDisplayTime: 10000,
        });
        return;
      case DatatransPaymentStatus.ERROR:
        setNotification({
          icon: (
            <ExclamationCircleIcon
              className="h-6 w-6 text-red-400"
              aria-hidden="true"
            />
          ),
          title: t('error.title'),
          message: t('error.message'),
          maxDisplayTime: 10000,
        });
        return;
      case DatatransPaymentStatus.CANCELLED:
        setNotification({
          icon: (
            <XCircleIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
          ),
          title: t('cancelled.title'),
          message: t('cancelled.message'),
          maxDisplayTime: 10000,
        });
        return;
    }
  }, [payment_status, setNotification]);
}
