import { FunctionComponent } from 'react';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Feed } from '../../Common/DataDisplay/Feed';
import { getLicenseCategoryColorHex } from '../TimeTrackingHelpers';
import { formatName } from '../../../utilities/formatName';
import { Routes } from '../../../utilities/routes';
import { UserType, useAppContext } from '../../../context/appContext';
import { formatNameWithLabel } from '../../../utilities/students/formatNameWithLabel';
import { LicenseCategory, WorkEvent } from '@tr-types/backend-types';

interface Props {
  events: WorkEvent[];
  addShadowTime?: boolean;
}

export const EventFeed: FunctionComponent<Props> = ({
  events,
  addShadowTime,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'eventsList' });
  const { userType } = useAppContext(UserType.BOTH);
  const router = useRouter();

  function goToEvent(event: WorkEvent) {
    void router.push(Routes.Calendar.CalendarView(event.id));
  }

  function renderEventItem(event: WorkEvent) {
    let duration = dayjs.duration(
      dayjs(event.end_time).diff(dayjs(event.start_time)),
    );
    if (addShadowTime) {
      duration = duration.add(event.billingType?.shadow_time ?? 0, 'm');
    }
    return (
      <div className="w-full grid grid-cols-3 sm:grid-cols-5 pl-3 justify-between space-x-4 ">
        <div className="self-center col-span-2">
          <p className="text-sm text-black">
            {event.student
              ? formatNameWithLabel(event.student)
              : event.billingType?.title}
          </p>
          <p className="text-xs text-gray-500 truncate">
            {dayjs(event.start_time).format('HH:mm')} -
            {dayjs(event.end_time).format('HH:mm')}
          </p>
        </div>
        <div className="self-center hidden sm:block col-span-2">
          <p className="text-xs text-gray-500 truncate">
            {event.student
              ? event.billingType?.title
              : event.user && `${t('user')}: ${formatName(event.user)}`}
          </p>
          {event.vehicle && (
            <p className="text-xs text-gray-500 truncate">
              {`${t('vehicle')}: ${
                event.vehicle?.title || event.vehicle?.license_plate
              }`}
            </p>
          )}
        </div>
        <div className="text-right text-sm whitespace-nowrap text-gray-500 select-none">
          {duration.hours()}h{' '}
          {duration.minutes() ? duration.minutes() + 'm' : ''}
        </div>
      </div>
    );
  }

  return (
    <Feed<WorkEvent>
      items={events.sort((a, b) =>
        dayjs(a.start_time).diff(dayjs(b.start_time)),
      )}
      onItemClicked={userType === UserType.STUDENT ? undefined : goToEvent}
      renderItem={renderEventItem}
      getItemColor={(e) =>
        getLicenseCategoryColorHex(e.billingType!.licenseCategory)
      }
      getItemInitials={(e) =>
        e.billingType!.licenseCategory === LicenseCategory.None
          ? '-'
          : e.billingType!.licenseCategory
      }
    />
  );
};
