import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { useAppContext } from '../../context/appContext';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { HttpEndpoints } from '../../data/httpEndpoints';
import {
  Student,
  StudentGroup,
  SyllabusProgressCard,
} from '../../typings/backend-types';
import { formatName } from '../../utilities/formatName';
import { Routes } from '../../utilities/routes';
import { Panel } from '../Common/Panel';
import { PagedTable } from '../Table/PagedTable';
import { Column } from '../Table/SortedFilteredTable/SortedFilteredTable';
import { SectionHeading } from '../Typography/SectionHeading';
import { DynamicExportButton } from '../Common/ExportImport/DynamicExportButton';
import useContract, { RestrictedFeatures } from '../../hooks/useContract';
import { formatNameWithLabel } from '../../utilities/students/formatNameWithLabel';

interface Props {
  fetchUrl: () => string;
  incompleteProgressCardsFetchUrl: () => string;
  includeInstructorColumn?: boolean;
}

export const StudentTaskTile: FunctionComponent<Props> = ({
  fetchUrl,
  incompleteProgressCardsFetchUrl,
  includeInstructorColumn,
  children,
}) => {
  const { organizationId } = useAppContext();

  const { data: students } = useSWR<Student[]>(fetchUrl, authenticatedFetcher, {
    fallbackData: [],
  });

  const { data: incompleteProgressCards } = useSWR<SyllabusProgressCard[]>(
    incompleteProgressCardsFetchUrl,
    authenticatedFetcher,
    {
      fallbackData: [],
    },
  );

  const { data: orgGroups } = useSWR<StudentGroup[]>(
    () =>
      HttpEndpoints.StudentGroupEndpoints.getStudentGroupsForOrganization(
        organizationId,
      ),
    authenticatedFetcher,
    { fallbackData: [] },
  );

  const { setErrorMessage } = useErrorPopupContext();
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard.tasks' });
  const { isFeatureEnabled } = useContract();

  function findTodo(student: Student): string {
    if (!student.email) return t('taskNames.emailMissing');
    if (!student.phone) return t('taskNames.phoneMissing');
    if (!student.firstName) return t('taskNames.firstNameMissing');
    if (!student.lastName) return t('taskNames.lastNameMissing');
    if (!student.dateOfBirth) return t('taskNames.dateOfBirthMissing');
    if (Object.values(student.primaryAddress).some((v) => !v)) {
      return t('taskNames.addressMissing');
    }
    if (
      isFeatureEnabled(RestrictedFeatures.SYLLABI) &&
      !(student.syllabusProgressCards?.length > 0)
    ) {
      return t('taskNames.progressCardMissing');
    }
    if (!(student.attachmentFilenames?.length > 0)) {
      return t('taskNames.attachmentMissing');
    }
    if (!(student.groups?.length > 0) && orgGroups.length > 0) {
      return t('taskNames.groupsMissing');
    }
    if (
      isFeatureEnabled(RestrictedFeatures.SYLLABI) &&
      incompleteProgressCards.find((pc) => pc.student.id === student.id)
    ) {
      return t('taskNames.progressCardIncomplete');
    }

    return null;
  }

  function todoAction(student: Student): string {
    if (
      !student.email ||
      !student.phone ||
      !student.firstName ||
      !student.lastName ||
      !student.dateOfBirth ||
      Object.values(student.primaryAddress).some((v) => !v)
    ) {
      return Routes.Students.EditView(student.id);
    } else if (
      !(student.syllabusProgressCards?.length > 0) &&
      isFeatureEnabled(RestrictedFeatures.SYLLABI)
    ) {
      return Routes.Students.ProgressCards(student.id).Overview;
    } else if (!(student.attachmentFilenames?.length > 0)) {
      return Routes.Students.FileAttachments(student.id);
    } else if (!(student.groups?.length > 0)) {
      return Routes.Students.EditView(student.id);
    }
    const incompleteProgressCard =
      isFeatureEnabled(RestrictedFeatures.SYLLABI) &&
      incompleteProgressCards.find((pc) => pc.student.id === student.id);
    if (
      isFeatureEnabled(RestrictedFeatures.SYLLABI) &&
      incompleteProgressCard
    ) {
      return Routes.Students.ProgressCards(student.id).DetailView(
        incompleteProgressCard.id,
      );
    }
  }

  const todoColumns: Column<Student>[] = [
    {
      header: t('taskColumn'),
      prop: (student: Student) => findTodo(student),
    },
    {
      header: t('studentColumn'),
      prop: (student: Student) => formatNameWithLabel(student),
    },
  ];

  if (includeInstructorColumn) {
    todoColumns.push({
      header: t('instructorColumn'),
      prop: (s) => formatName(s.instructor),
    });
  }

  return (
    <Panel className="break-inside-avoid">
      <div className="flex">
        <SectionHeading className="flex-grow">
          {t('tableHeader')}
        </SectionHeading>
        {children}
        <DynamicExportButton
          small
          data={students.filter(findTodo).map((student) => ({
            [t('taskColumn')]: findTodo(student),
            [t('studentColumn')]: formatName(student),
            [t('instructorColumn')]: formatName(student.instructor),
          }))}
          exportFileName={t('tableHeader')}
        />
      </div>
      <PagedTable
        fetchUrl={fetchUrl}
        filter={(s: Student) => findTodo(s) !== null}
        columns={todoColumns}
        limit={5}
        syncPageWithQueryParamKey="todoPage"
        onError={() => setErrorMessage(t('tableErrorMessage'))}
        altText={t('tableAltText')}
        showSearch={false}
        rowLink={todoAction}
      />
    </Panel>
  );
};
