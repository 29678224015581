import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../context/appContext';
import { HttpEndpoints } from '../../../data/httpEndpoints';
import { DashboardComponent } from '../../../typings/backend-types';
import useUserPermission from '../../../hooks/useUserPermission';
import { Permission, Scope } from '../../../typings/roleConfig';
import { DashboardPermissionMissingHint } from '../PermissionMissingHint';
import { StudentsTile } from './StudentsTile';

export const MyStudentsTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.drivingStudents',
  });
  const { userId } = useAppContext();
  const fetchUrl = () =>
    HttpEndpoints.StudentEndpoints.getStudentsForInstructor(userId, {
      archived: false,
    });

  const canSeeOwnStudents = useUserPermission(
    Permission.OWN_STUDENTS_INFO,
    Scope.READ,
  );

  if (!canSeeOwnStudents) {
    return (
      <DashboardPermissionMissingHint
        dashboardComponent={DashboardComponent.MY_STUDENTS}
      />
    );
  }

  return <StudentsTile fetchUrl={fetchUrl} title={t('myStudents')} />;
};
