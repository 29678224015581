import { FormEvent, FunctionComponent, useState } from 'react';
import {
  ArrowTopRightOnSquareIcon,
  BanknotesIcon,
} from '@heroicons/react/24/outline';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { SlideOverPanel } from '../Common/SlideOverPanel';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { useDatatransPaymentFeedback } from '../../hooks/PaymentProcessing/useDatatransPaymentFeedback';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { Button } from '../Common/Buttons/Button';
import { UserType, useAppContext } from '../../context/appContext';
import { FEATURE } from '../../FEATURES';
import { BasicTextInput } from '../Forms/FormFields/BasicTextInput';
import { InfoText } from '../Common/InfoText';
import { getDecimalPlaces } from '../../utilities/decimalPlaces';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import useContract, { RestrictedFeatures } from '../../hooks/useContract';

export const StudentPaymentInitiationForm: FunctionComponent = () => {
  const { organizationId, userId } = useAppContext(UserType.STUDENT);
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.paymentInitiationForm',
  });

  const { isFeatureEnabled: isFeatureToggleActive } = useFeatureToggles();
  const { setErrorMessage } = useErrorPopupContext();
  const { isFeatureEnabled: isContractFFSet } = useContract();
  useDatatransPaymentFeedback();
  const { data: isDatatransEnabled } = useSWR<boolean>(
    () => HttpEndpoints.OrganizationEndpoints.checkDatransSetup(organizationId),
    authenticatedFetcher,
    {
      fallbackData: false,
    },
  );

  const [formIsOpen, setFormIsOpen] = useState(false);

  if (
    !isFeatureToggleActive(FEATURE.STUDENT_PAYMENT_INITIATION) ||
    !isDatatransEnabled ||
    !isContractFFSet(RestrictedFeatures.PAYMENT_PROCESSING)
  ) {
    return null;
  }

  async function onSubmit(
    e: FormEvent<{ amount: HTMLInputElement } & HTMLFormElement>,
  ) {
    e.preventDefault();
    const amount = Number(e.currentTarget.amount.value);
    if (isNaN(amount) || getDecimalPlaces(amount) > 2) {
      setErrorMessage(t('amountIsInvalid'));
      return;
    }
    try {
      const callbackBaseUrl = window?.location?.origin;
      const { transactionId } = await authenticatedFetcher(
        HttpEndpoints.PaymentEndpoints.getTransactionId(
          userId,
          organizationId,
          Math.round(amount * 100),
          callbackBaseUrl + `?payment_status=success`,
          callbackBaseUrl + `?payment_status=error`,
          callbackBaseUrl + `?payment_status=cancelled`,
        ),
      );
      window.location.href = `${process.env.NEXT_PUBLIC_DATATRANS_BASE_URL}/v1/start/${transactionId}`;
    } catch (e) {
      setErrorMessage(t('couldNotInitiatePayment'));
    }
  }

  return (
    <>
      <Button
        color="primary"
        icon={BanknotesIcon}
        onClick={() => setFormIsOpen(true)}
      >
        {t('initiatePayment')}
      </Button>
      <SlideOverPanel
        open={formIsOpen}
        onClose={() => setFormIsOpen(false)}
        icon={BanknotesIcon}
        title={t('initiatePayment')}
      >
        <InfoText className="mb-3">{t('infoText')}</InfoText>
        <form onSubmit={onSubmit}>
          <BasicTextInput
            label={t('amount')}
            formName="amount"
            type="positiveNumber"
            required
            min={0.01}
            step={0.01}
            small
          />
          <Button
            color="primary"
            className="mt-3"
            type="submit"
            icon={ArrowTopRightOnSquareIcon}
          >
            {t('submit')}
          </Button>
        </form>
      </SlideOverPanel>
    </>
  );
};
