import { FunctionComponent } from 'react';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import { UserType, useAppContext } from '../../context/appContext';
import { EventList } from '../TimeTracking/MonthlyDetailView/EventsList';
import { WorkEvent } from '../../typings/backend-types';
import { HttpEndpoints } from '../../data/httpEndpoints';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { useErrorPopupContext } from '../../context/errorPopupContext';

export const StudentLessonList: FunctionComponent = () => {
  const { user: student, organizationId } = useAppContext(UserType.STUDENT);
  const { setErrorMessage } = useErrorPopupContext();
  const { t } = useTranslation('translation', {
    keyPrefix: 'students.lessons',
  });

  const { data: calendarEvents } = useSWR<WorkEvent[] | null>(
    () =>
      HttpEndpoints.WorkEventEndpoints.getWorkEventsForStudent(
        student.id,
        organizationId,
      ),
    authenticatedFetcher,
    {
      onError: () => setErrorMessage(t('couldNotFetchMessage')),
      fallbackData: [],
    },
  );

  if (calendarEvents && calendarEvents.length > 0) {
    return <EventList events={calendarEvents} addShadowTime={false} />;
  }

  return <p className="text-gray-400 font-normal mt-5 ml-3">{t('altText')}</p>;
};
