import { FunctionComponent, useEffect, useState } from 'react';
import { HttpEndpoints } from '../../data/httpEndpoints';

import useContract, { RestrictedFeatures } from '../../hooks/useContract';
import { useUserTeam } from '../../hooks/useUserTeam';
import { BasicSelectInput } from '../Forms/FormFields/BasicSelectInput';
import { formatName } from '../../utilities/formatName';
import { User } from '../../typings/backend-types';
import { StudentTaskTile } from './StudentTaskTile';

export const TeamStudentsTaskTile: FunctionComponent = () => {
  const { isFeatureEnabled } = useContract();
  const team = useUserTeam();

  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    team[0]?.id,
  );

  useEffect(() => {
    if (team.length > 0) {
      setSelectedUserId(team[0].id);
    }
  }, [team]);

  return (
    <StudentTaskTile
      incompleteProgressCardsFetchUrl={() =>
        isFeatureEnabled(RestrictedFeatures.SYLLABI) &&
        HttpEndpoints.ProgressCardEndpoints.getIncompleteCards(selectedUserId)
      }
      fetchUrl={() =>
        HttpEndpoints.StudentEndpoints.getStudentsForInstructor(
          selectedUserId,
          {
            archived: false,
          },
        )
      }
      includeInstructorColumn={false}
    >
      <BasicSelectInput<User>
        small
        hideNoneSelect={team.length > 0}
        className="mr-2"
        options={team}
        bindLabel={formatName}
        bindValue={(user) => user.id}
        value={selectedUserId}
        onChange={(e) => setSelectedUserId(e.target.value)}
      />
    </StudentTaskTile>
  );
};
