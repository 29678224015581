import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import useSWR from 'swr';
import { Panel } from '../Common/Panel';
import { LoadingIndicator } from '../Common/LoadingIndicator';
import { SectionHeading } from '../Typography/SectionHeading';
import { StackedList, StackedListItem } from '../Table/StackedList';
import { formatName } from '../../utilities/formatName';
import { useAppContext } from '../../context/appContext';
import authenticatedFetcher from '../../data/authenticatedFetcher';
import { HttpEndpoints } from '../../data/httpEndpoints';
import { useErrorPopupContext } from '../../context/errorPopupContext';
import { UserBirthday } from '../../typings/backend-types';

export const EmployeeBirthdaysTile: FunctionComponent = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'dashboard.employeeBirthdays',
  });

  const { organizationId } = useAppContext();
  const { setErrorMessage } = useErrorPopupContext();

  const { data: usersWithUpcomingBirthday = [], isLoading: isLoadingUsers } =
    useSWR<UserBirthday[]>(
      () => HttpEndpoints.UserEndpoints.getUserBirthdaysForOrg(organizationId),
      authenticatedFetcher,
      {
        onError: () => setErrorMessage(t('couldNotGetBirthdays')),
      },
    );

  function formatBirthday(birthday: Dayjs): string {
    if (birthday.isSame(dayjs(), 'day')) {
      return t('today');
    }
    if (birthday.isSame(dayjs().add(1, 'day'), 'day')) {
      return t('tomorrow');
    }
    return birthday.format('DD.MM.');
  }

  if (isLoadingUsers) {
    return (
      <Panel className="break-inside-avoid">
        <LoadingIndicator />
      </Panel>
    );
  }

  return (
    <Panel className="break-inside-avoid">
      <SectionHeading>{t('employeeBirthdays')}</SectionHeading>
      <StackedList>
        {usersWithUpcomingBirthday.length === 0 && (
          <StackedListItem className="text-sm">
            {t('noBirthdays')}
          </StackedListItem>
        )}
        {usersWithUpcomingBirthday.map((user) => {
          const age =
            dayjs(user.nextBirthday).year() - dayjs(user.dateOfBirth).year();
          const translationKey =
            age % 10 === 1
              ? 'stBirthday'
              : age % 10 === 2
              ? 'ndBirthday'
              : age % 10 === 3
              ? 'rdBirthday'
              : 'thBirthday';
          return (
            <StackedListItem
              key={`${user.lastName}-${user.firstName}`}
              className="text-sm"
              paddingY={3}
            >
              {formatName(user)}
              <span>
                {formatBirthday(dayjs(user.nextBirthday))} (
                {t(translationKey, {
                  age,
                })}
                )
              </span>
            </StackedListItem>
          );
        })}
      </StackedList>
    </Panel>
  );
};
